<template>
  <!--    编辑-->
  <el-dialog
      :title="formTitle"
      :visible.sync="messageBox"
      width="500px"
      class="addAlameBox"
      :close-on-click-modal="false"
  >
    <FormBox
        ref="formRef"
        :showFlag="showFlag"
        :formArr="formArr"
        :record="addForm"
        @saveForm="saveForm"
        @handleCancel="handleCancel"
        :fileListModify="fileListModify"
    ></FormBox>
  </el-dialog>
</template>

<script>
import FormBox from "@/components/lxw/Form/index.vue";

export default {
  components: {FormBox},
  props: {
    formTitle: {
      type: String,
      default: "新增",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      formArr: [
        {
          label: "专题名称",
          key: "origin",
          type: "Input",
        },
        {
          label: "专题类型",
          key: "productName",
          type: "Select",
          disabled: "disabled",
          options: [
            {
              value: '拐枣酒',
              label: '拐枣酒',
            },
            {
              value: '拐枣茶',
              label: '拐枣茶',
            },
            {
              value: '拐枣饮料',
              label: '拐枣饮料',
            },
            {
              value: '拐枣醋',
              label: '拐枣醋',
            },
            {
              value: '拐枣养生',
              label: '拐枣养生',
            },
            {
              value: '其他',
              label: '其他',
            },
          ]
        },
        {
          label: "专题数量",
          key: "quality",
          type: "InputNumber",
        },
        /* {
           label: "计量单位",
           key: "unit",
           type: "Input",
         },

        {
          label: "开始日期",
          key: "beginDate",
          type: "DateTime",
        }, {
          label: "结束日期",
          key: "endDate",
          type: "DateTime",
        },
        {
          label: "负责人",
          key: "curator",
          type: "Input",
        }, {
          label: "企业",
          key: "manufacturer",
          type: "Input",
        },*/

      ],
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: []
    };
  },
  watch: {
    record: {
      handler(newVal, oldVal) {
        //console.log(newVal, 'newVal')
        this.addForm = newVal;
        this.fileListModify = []
      },
      immediate: true,
      deep: true,
    },
  },
  async created() {


  },
  mounted() {
    // //console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const {fileList} = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
              item.response &&
              item.response.data &&
              item.response.data.fileName
          ) {
            list.push(item.response.data.fileName);
          }
        });
        this.filesList = list;
      }
    },

    // 确认提交
    saveForm(param) {

      let url = "/pro2501/prodplan/save";
      // if(this.formTitle == '新增'){
      //   delete this.addForm.id;
      // }


      param.type = "专题"

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "新增") {
            this.$message.success("添加成功");
          } else {
            this.$message.success("修改成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 重置字段
    resetForm() {
      this.$refs["formRef"].resetForm();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>